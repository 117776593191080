<template>
  <div class="interview">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="936px"
    >
      <div class="examRule_head flex">
        <span>{{ form.id ? '编辑' : '新增' }}面试</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="interview_main">
        <el-form
          ref="form"
          :rules="rules"
          class="form"
          :model="form"
          label-width="100px"
        >
          <el-form-item label="姓名:" prop="name">
            <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="性别:">
            <el-select v-model="form.sex" placeholder="请选择性别">
              <el-option
                v-for="item in sexList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电话:" prop="mobile">
            <el-input v-model="form.mobile" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="身份证号:">
            <el-input v-model="form.identity" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="面试时间:">
            <el-date-picker
              v-model="form.mianshi_time"
              type="date"
              placeholder="选择面试时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="面试区域:">
            <el-cascader
              size="large"
              :options="regionList"
              v-model="region"
              placeholder="请选择所在区域"
              @change="handleChangeCity"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="门店:">
            <el-select
              clearable
              :disabled="!form.city"
              v-model="form.dian_id"
              placeholder="请选择门店"
            >
              <el-option
                v-for="item in shopList"
                :key="item.id"
                :label="item.dian_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="面试职位:">
            <el-select
              v-model="form.mian_position"
              placeholder="请选择面试职位"
            >
              <el-option
                v-for="item in positionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="邀约人:">
            <el-select v-model="form.yao_name" placeholder="请选择邀约人">
              <el-option
                v-for="item in personnelList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电话:">
            <el-input
              v-model="form.yao_mobile"
              maxlength="11"
              placeholder="请输入邀约人电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="邀约时间:">
            <el-date-picker
              v-model="form.yao_time"
              type="date"
              placeholder="选择邀约时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="审批人:">
            <el-select
              @change="changeApprover"
              v-model="form.shen_name"
              placeholder="请选择审批人"
            >
              <el-option
                v-for="item in personnelList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职位:">
            <el-input v-model="form.shen_positions" disabled placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="工号:">
            <el-input v-model="form.shen_id" disabled placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="人员来源:">
            <el-select v-model="form.user_qudao" placeholder="请选择人员来源">
              <el-option
                v-for="item in sourceList"
                :key="item.id"
                :label="item.title_type"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="interview_main_footer center">
          <el-button @click="submit('form')">提交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import index from "../common/index";
import { regionData, CodeToText } from "element-china-area-data";
export default {
  mixins: [index],
  data() {
    var validatePass = (rule, value, callback) => {
      if (!this.form.mobile.match(/^(0|86|17951)?1[3456789]\d{9}$/)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };
    return {
      regionList: regionData,
      CodeToText: CodeToText,
      dialogVisible: false,
      form: {
        name: "",
        sex: "",
        mobile: "",
        identity: "",
        mian_position: "",
        yao_name: "",
        yao_mobile: "",
        city: "",
        dian_id: "",
        yao_time: "",
        mianshi_time: "",
        user_qudao: "",
        shen_name: "",
        shen_position: "",
        shen_positions:'',
        shen_id: "",
        id:''
      },
      sexList: [
        {
          value: "男",
          label: "男",
        },
        {
          value: "女",
          label: "女",
        },
      ],
      shopList: [], //面试门店
      positionList: [
        {
          value: 1,
          label: "区域总监"
        },
        {
          value: 2,
          label: "店长"
        },
        {
          value: 3,
          label: "经理"
        },
        {
          value: 4,
          label: "经纪人"
        },
        {
          value: 5,
          label: "人事"
        },
      ], //职位列表
      personnelList: [], //人事信息
      sourceList: [], //来源
      region: [], //区域
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
            { validator: validatePass, trigger: 'blur' }
          ],
      },
    };
  },
  methods: {
    //提交
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios[this.form.id ? 'interviewEdit' : 'interviewAdd']({
              ...this.form,
              yao_time: this.form.yao_time ? this.form.yao_time / 1000 : "",
              mianshi_time: this.form.mianshi_time
                ? this.form.mianshi_time / 1000
                : "",
            })
            .then((res) => {
              this.$message({
                message: res.msg,
                type: "success",
              });
              for (const key in this.form) {
                this.form[key] = ''
              }
              this.region = []
              this.dialogVisible = false;
              this.$emit("success");
            });
        } else {
          return false;
        }
      });
    },
    getForm () {
      this.form ={
        name: "",
        sex: "",
        mobile: "",
        identity: "",
        mian_position: "",
        yao_name: "",
        yao_mobile: "",
        city: "",
        dian_id: "",
        yao_time: "",
        mianshi_time: "",
        user_qudao: "",
        shen_name: "",
        shen_position: "",
        shen_positions:'',
        shen_id: "",
        id:''
      }
    },
    //选择区域
    handleChangeCity() {
      let data = [];
      for (let i = 0; i < this.region.length; i++) {
        data.push(this.CodeToText[this.region[i]]);
      }
      this.form.city = data.join("/");
      this.getMenDian()
    },
    //选择审批人
    changeApprover(e) {
      let data = {};
      data = this.personnelList.filter((em) => e == em.name)[0];
      this.form.shen_id = data.id;
      this.form.shen_positions = this.getPosition(data.renshi_position);
      this.form.shen_position = data.renshi_position;
    },
    getPosition (position) {
      let txt = ''
      switch (position) {
        case 1:
          txt = '人事专员'
          break;
        case 2:
          txt = '人事主任'
          break;
        case 3:
          txt = '人事经理'
          break;
        default:
          break;
      }
      return txt
    },
    close() {
      this.dialogVisible = false;
    },
    open(id) {
      id ? this.form.id = id : this.region = []
      id ? this.getInterview(id) : this.getForm()
      this.dialogVisible = true;
      this.getRenShi()
    },
    //面试详情
    getInterview (id) {
     
      this.$axios.interviewDetail({id}).then(res=>{
       
       let {name,sex,mobile,identity,mian_position,yao_mobile,city,shen_name,shen_position,shen_positions,shen_id,id} = res.data
       this.form = {...this.form,name,sex,mobile,identity,mian_position,yao_mobile,city,shen_name,shen_position,shen_positions,shen_id,id}
       
       res.data.city ? this.getMenDian() : ''
       this.form.user_qudao = res.data.user_qudao_id
       this.form.dian_id = res.data.mendian_id
       this.form.yao_name = res.data.yao_name_id
       this.form.mianshi_time = new Date(res.data.mianshi_time) * 1
       this.form.yao_time = new Date(res.data.yao_time) * 1
       this.form.shen_positions = this.getPosition(res.data.shen_position)
       let data = []
       res.data.city.split('/').forEach(item=>{
        for (const key in this.CodeToText) {
          if (this.CodeToText[key] == item) {
            data.push(key)
          }
        }
       })
       this.region = data
      })
    },
    getMenDian () {
      this.$axios.menDianC({city:this.form.city}).then((res) => {
        this.shopList = res.data;
      });
    },
    //获取门店列表
    getRenShi() {
      
      this.$axios
        .renShi()
        .then((res) => {
          this.personnelList = res.data;
        });
      this.$axios.quDao().then((res) => {
        this.sourceList = res.data;
      });
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
.form {
  display: flex;
  padding: 30px 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  /deep/ .el-input {
    width: 320px;
  }
  /deep/ .el-input__inner{border:1px solid #ccc;border-radius:0;}
}
.interview_main_footer {
  padding: 30px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
