// 面试管理
<template>
  <div class="interview">
    <div class="interview_box">
      <div class="interview_head flex b-c-f p-r-20 m-b-10">
        <ul class="interview_head_ul flexs">
          <li
            @click="changeAudit(index)"
            class="center"
            :class="{ active: index == form.status }"
            v-for="(item, index) in 3"
            :key="index"
          >
            {{ index == 0 ? "审核中" : index == 1 ? "已通过" : "已拒绝" }}
          </li>
        </ul>
        <el-button @click="$refs.interview.open()">新增面试</el-button>
      </div>
      <div class="interview_main b-c-f">
        <div class="interview_main_head">
          <div class="interview_main_head_box">
            <el-form ref="form" class="form" :model="form" label-width="80px">
              <el-form-item label="面试时间:">
                <el-date-picker
                  v-model="form.mianshi_time"
                  type="date"
                  @change="init"
                  placeholder="选择面试时间">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="区域:">
                <el-cascader
                    clearable
                    size="large"
                    :options="regionList"
                    v-model="cityList"
                    placeholder="请选择所在区域"
                    @change="handleChangeCity"
                  >
                  </el-cascader>
              </el-form-item>
              <el-form-item label="门店:">
                <el-select @change="init" clearable v-model="form.dian_id" placeholder="请选择门店">
                  <el-option
                    v-for="item in areaList"
                    :key="item.id"
                    :label="item.dian_name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="邀约人:">
                <!-- <el-input @input="init" v-model="form.yao_name" placeholder="请输入邀约人"></el-input> -->
                <el-select @change="init" clearable v-model="form.yao_name" placeholder="请选择邀约人">
                  <el-option
                    v-for="item in personnelList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="面试人:">
                <el-input @input="init" v-model="form.name" placeholder="请输入面试人"></el-input>
              </el-form-item>
            </el-form>
          </div>
          
        </div>
        <ul class="interview_ul">
          <li v-for="(item,index) in interviewList" :key="index" class="flex" :class="{active:index % 2 != 0}">
            <div class="interview_ul_box">
              <div class="interview_main_ul flexs">
                <div class="interview_mian_li">
                  <span>姓名：</span>
                  <span>{{ item.name }}</span>
                </div>
                <div class="interview_mian_li">
                  <span>电话：</span>
                  <span>{{ item.mobile }}</span>
                </div>
                <div class="interview_mian_li">
                  <span>身份证号：</span>
                  <span>{{ item.identity }}</span>
                </div>
              </div>
              <div class="interview_main_ul flexs m-t-15">
                <div class="interview_mian_li">
                  <span>性别：</span>
                  <span>{{ item.sex }}</span>
                </div>
                <div class="interview_mian_li">
                  <span>面试职位：</span>
                  <span>{{ getPosition(item.mian_position) }}</span>
                </div>
                <div class="interview_mian_li">
                  <span>面试时间：</span>
                  <span>{{ item.mianshi_time }}</span>
                </div>
              </div>
            </div>
            <div class="flex">
              <div v-if="item.status == 0" class="interview_ul_btn m-r-20" @click="$refs.interview.open(item.id)">编辑</div>
              <div class="interview_ul_btn" @click="$refs.pop.open(item.id)">查看详情</div>
            </div>
            
          </li>
        </ul>
        <div class="paging" v-if="interviewList.length">
          <el-pagination
            background
            @current-change="changePage"
            layout="prev, pager, next, jumper, ->, total"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 新增面试 S -->
    <add-interview ref="interview" @success="success"></add-interview>
    <!-- 新增面试 E -->
    <!-- 面试详情弹出 S -->
    <interview-pop ref="pop" @success="success"></interview-pop>
    <!-- 面试详情弹出 E -->
  </div>
</template>

<script>
import index from '../../common/index'
import AddInterview from '../../components/AddInterview.vue';
import InterviewPop from '../../components/InterviewPop'
import { regionData, CodeToText } from "element-china-area-data";
export default {
  components: { AddInterview,InterviewPop },
  mixins: [index],
  data() {
    return {
      regionList: regionData,
      CodeToText: CodeToText,
      cityList: [], //区域列表
      total: 0, //总条数
      interviewList: [], //面试列表
      form: {
        page: 1, //分页
        status:0,
        mianshi_time:'',
        yao_name:'',
        name:'',
        dian_id:'',
        city:''
      },
      areaList:[],//门店列表
      personnelList:[],//人事列表
    };
  },
  methods: {
    changePage (e) {
      this.form.page = e
      this.getInterviewList()
    },
    //添加成功
    success () {
      this.init()
    },
    //切换审核
    changeAudit(index) {
      this.form.status = index;
      this.init();
    },
    //初始化
    init () {
      this.form.page = 1;
      this.getInterviewList();
    },
    //选择意向位置
    handleChangeCity() {
      let data = [];
      for (let i = 0; i < this.cityList.length; i++) {
        data.push(this.CodeToText[this.cityList[i]]);
      }
      this.form.city = data.join("/");
      this.init()
    },
    //获取面试列表
    getInterviewList() {
      this.$axios.interviewList({...this.form,mianshi_time:this.form.mianshi_time ? this.form.mianshi_time / 1000 : ''}).then((res) => {
        if (res.data) {
          this.total = res.data.total
          this.interviewList = res.data.data
        }
        
      });
    },
    //获取门店列表
    getMenDian () {
      this.$axios.menDian().then(res=>{
        this.areaList = res.data
      })
    },
    getrenShi () {
      this.$axios.renShi().then(res=>{
        this.personnelList = res.data
      })
    }
  },
  created() {
    this.getMenDian()
    this.getInterviewList();
    this.getrenShi()
  },
};
</script>

<style lang="less" scoped>
.interview_head_ul {
  padding-left: 40px;
  li {
    cursor: pointer;
    color: #666666;
    height: 64px;
    font-size: 18px;
    margin-right: 60px;
  }
  .active {
    color: #3273f6;
    font-weight: bold;
    position: relative;
    &::before {
      left: 50%;
      width: 24px;
      height: 2px;
      bottom: 0;
      content: "";
      position: absolute;
      transform: translateX(-50%);
      background: #3273f6;
    }
  }
}
/deep/ .el-button {
  width: 100px;
  color: #ffffff;
  font-size: 16px;
  background: #3273f6;
  border-radius: 4px;
}
.interview_main_head {
  
  .interview_main_head_box {
    padding: 30px 20px 0 20px;
    border-bottom: 1px solid #EAEAEA;
  }
  /deep/ .el-input {
    width: 202px;
  }
}
.form {
  display: flex;
  flex-wrap: wrap;
}
.interview_ul {
  li {
    padding: 20px 40px;
  }
  .active {
    background: #F7FCFF;
  }
  .interview_ul_btn {
    cursor: pointer;
    color: #3273F6;
    font-size: 16px;
  }
  .interview_main_ul {
    .interview_mian_li {
      span {
        color: #999999;
        font-size: 16px;
        &:last-child {
          color: #333333;
        }
      }
      &:first-child {
        width: 160px;
      }
      &:nth-child(2) {
        width: 200px;
      }
    }
  }
}
</style>
