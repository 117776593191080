<template>
  <div class="interview">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="examRule_head flex">
        <span>面试详情</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="interview-detail">
        <div class="tit">面试人信息</div>
        <div class="detail-item">
          <div class="detail-li">
            <span class="color-9">姓名：</span> {{ details.name }}
          </div>
          <div class="detail-li">
            <span class="color-9">性别：</span> {{ details.sex }}
          </div>
          <div class="detail-li">
            <span class="color-9">电话：</span> {{ details.mobile }}
          </div>
          <div class="detail-li">
            <span class="color-9">身份证号：</span>{{ details.identity }}
          </div>
          <div class="detail-li">
            <span class="color-9">面试职位：</span>
            {{ getPosition(details.mian_position) }}
          </div>
        </div>
        <div class="tit">邀约人信息</div>
        <div class="detail-item">
          <div class="detail-li">
            <span class="color-9">姓名：</span> {{ details.yao_name }}
          </div>
          <div class="detail-li">
            <span class="color-9">电话：</span>{{ details.yao_mobile }}
          </div>
        </div>
        <div class="tit">审批人信息</div>
        <div class="detail-item">
          <div class="detail-li">
            <span class="color-9">姓名：</span> {{ details.shen_name }}
          </div>
          <div class="detail-li">
            <span class="color-9">工号：</span> {{ details.shen_id }}
          </div>
          <div class="detail-li">
            <span class="color-9">职位：</span
            >{{ getPosition(details.shen_position) }}
          </div>
        </div>
        <div class="tit">其他信息</div>
        <div class="detail-item">
          <div class="detail-li">
            <span class="color-9">区域：</span> {{ details.city }}
          </div>
          <div class="detail-li">
            <span class="color-9">门店：</span>{{ details.dian_id }}
          </div>
          <div class="detail-li">
            <span class="color-9">邀约时间：</span> {{ details.yao_time }}
          </div>
          <div class="detail-li">
            <span class="color-9">面试时间：</span>{{ details.mianshi_time }}
          </div>
          <div class="detail-li" v-if="details.status">
            <span class="color-9">{{ details.status == 1 ? '通过' : '拒绝' }}时间：</span> {{ details.updatetime }}
          </div>
          <div class="detail-li">
            <span class="color-9">人员来源：</span> {{ details.user_qudao }}
          </div>
          <div class="detail-li" v-if="details.status == 2">
            <span class="color-9">拒绝原因：</span> {{ details.user_qudao }}
          </div>
        </div>
      </div>
      <div class="interview_footer flex" v-if="!details.status">
        <el-button @click="changeInterview(2)">拒绝</el-button>
        <el-button @click="changeInterview(1)">通过</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id:null,//
      dialogVisible: false,
      details: {}, //详情
    };
  },
  methods: {
    changeInterview (status) {
      this.$axios.interviewStatus({id:this.id,status}).then(res=>{
        this.$emit('success')
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.dialogVisible = false
      })
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(id) {
      this.id = id
      this.$axios.interviewDetail({ id }).then((res) => {
        this.dialogVisible = true;
        this.details = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
.form {
  display: flex;
  padding: 30px 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  /deep/ .el-input {
    width: 320px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
.interview_main_footer {
  padding: 30px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
.interview-detail {
  padding: 10px 30px 20px 30px;
  .tit {
    font-size: 18px;
    margin: 20px 0 10px 0px;
  }
  .detail-item {
    display: flex;
    flex-wrap: wrap;
  }
  .detail-li {
    width: 50%;
    margin: 10px 0px;
    font-size: 16px;
  }
}
.interview_footer {
  padding: 0 40px 40px 40px;
  /deep/ .el-button {
    width: 240px;
    height: 56px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    &:first-child {
      color: #FF5151;
      background: #FDDFDF;
    }
    &:last-child {
      color: #FFFFFF;
      background: #3273F6;
    }
  }
}
</style>
